import axios from "./axios";
import { loginEndpoint } from "./endpoints";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CURRENT_USER,
  CURRENT_USER_PERMISSIONS,
} from "../Constants/authConstants";

export const LogIn = async (email, password) => {
  const user = {
    email,
    password,
  };
  // send login request and get access token
  const { data: loginData } = await axios.post(loginEndpoint, user);
  localStorage.setItem(
    CURRENT_USER_PERMISSIONS,
    JSON.stringify(loginData.data.role, null, 2)
  );
  localStorage.setItem(ACCESS_TOKEN, loginData.data.token.accessToken);
  localStorage.setItem(
    CURRENT_USER,
    JSON.stringify(
      {
        id: loginData.data.id,
        email: loginData.data.email,
        merchantName: loginData.data.merchantName,
        image: loginData.data.image,
      },
      null,
      2
    )
  );
};

/**
 * we use it in register mode
 * after we get the jwt code from register method
 */
export const LogInWithJwt = (jwt) => {
  localStorage.setItem(ACCESS_TOKEN, jwt);
};

/**
 * when the user logout we remove
 * the cookies that contains the token and the userDetails
 */
export const LogOut = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(CURRENT_USER);
  localStorage.removeItem(CURRENT_USER_PERMISSIONS);
  window.location.reload("/login");
};

export const getCurrentUser = () => {
  try {
    return localStorage.getItem(CURRENT_USER);
  } catch (ex) {
    return null;
  }
};

export const getJwt = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export default {
  LogIn,
  LogInWithJwt,
  LogOut,
  getCurrentUser,
  getJwt,
};
